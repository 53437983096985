
import { computed, defineComponent, ref } from "vue";
import CenteredModal from "@/components/layout/modals/CenteredModal.vue";
import CustomButton from "@/components/inputs/customButton/CustomButton.vue";
import InputDefault from "@/components/inputs/inputDefault/InputDefault.vue";
import InputSwitch from "@/components/inputs/inputSwitch/InputSwitch.vue";
import InputSelect from "@/components/inputs/inputSelect/InputSelect.vue";
import InputMonth from "@/components/inputs/inputMonth/InputMonth.vue";
import { storeToRefs } from "pinia";
import { useCustomerStore } from "@/store/customer";
import { CustomerNormlistInterface } from "@/store/customer/interfaces";
import { useHelpersStore } from "@/store/helpers";
import { ToastMessageType } from "@/components/helpers/theToastMessage/toast_message_helpers";

export default defineComponent({
    name: "addNormlistModal",
    components: {
        CenteredModal,
        CustomButton,
        InputDefault,
        InputSwitch,
        InputSelect,
        InputMonth,
    },
    emits: ["close"],
    setup(props, ctx) {
        const { editCustomer } = storeToRefs(useCustomerStore());
        const { setToastMessage } = useHelpersStore();
        const toEditData = ref<CustomerNormlistInterface>({
            construction_type: "",
            type: "",
            date: "",
            format: 2010,
            include_rejected_norms: false,
        });

        const isMissingData = computed(() => {
            let isMissingData = true;
            if (toEditData.value.date && toEditData.value.format) {
                isMissingData = false;
            }

            return isMissingData;
        });

        function setDate(value: string): void {
            toEditData.value.date = value;
        }

        function generateCustomerNormList(): void {
            const apiUrl = process.env.VUE_APP_API_URL;
            const url =
                apiUrl +
                "/customers/" +
                editCustomer.value.id +
                "/norm-list?construction_type=" +
                toEditData.value.construction_type +
                "&type=" +
                toEditData.value.type +
                "&date=" +
                toEditData.value.date +
                "&format=" +
                toEditData.value.format +
                "&include_rejected_norms=" +
                (toEditData.value.include_rejected_norms ? 1 : 0);
            window.location.href = url;
            setToastMessage(
                ToastMessageType.SUCCESS,
                "Normenliste wurde erfolgreich erstellt."
            );
            ctx.emit("close");
        }
        return {
            isMissingData,
            editCustomer,
            toEditData,
            generateCustomerNormList,
            setDate,
        };
    },
});
