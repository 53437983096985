
import CustomButton from "@/components/inputs/customButton/CustomButton.vue";
import IconClose from "@/components/icons/IconCloseSideoverlay.vue";
import IconContainer from "@/components/helpers/iconContainer/IconContainer.vue";
import LoadingAnimation from "@/components/helpers/loadingAnimation/LoadingAnimation.vue";
import { computed, defineComponent } from "vue";
import { storeToRefs } from "pinia";
import { useCustomerStore } from "@/store/customer";
import { useHelpersStore } from "@/store/helpers";

export default defineComponent({
    name: "CustomerSideContainer",
    props: {
        open: {
            type: Boolean,
            required: true,
        },
    },
    emits: ["close", "editNorm", "openNormlist"],
    components: {
        CustomButton,
        IconClose,
        IconContainer,
        LoadingAnimation,
    },
    setup() {
        const { customer } = storeToRefs(useCustomerStore());
        const { loadingIds } = storeToRefs(useHelpersStore());

        const isLoading = computed(() => {
            return loadingIds.value.find(
                (el) => el.id == "fetchingCustomerData"
            );
        });
        return { customer, isLoading };
    },
});
