import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "relative h-full w-full flex flex-col overflow-hidden" }
const _hoisted_3 = { class: "hidden md:flex gap-3 w-full mt-6 mb-2" }
const _hoisted_4 = { class: "text-sm text-grey-darker" }
const _hoisted_5 = { class: "text-sm text-grey-darker" }
const _hoisted_6 = { class: "text-sm text-grey-darker" }
const _hoisted_7 = {
  key: 0,
  class: "flex justify-center items-center w-full h-full text-grey-darker"
}
const _hoisted_8 = {
  id: "customer_list",
  class: "overflow-y-auto pr-1 flex flex-col gap-1 h-full mt-3 md:mt-0"
}
const _hoisted_9 = {
  key: 1,
  class: "w-full flex justify-center h-8 bg-white"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_plus = _resolveComponent("icon-plus")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_input_search = _resolveComponent("input-search")!
  const _component_icon_arrow = _resolveComponent("icon-arrow")!
  const _component_customer_container = _resolveComponent("customer-container")!
  const _component_customer_mobile_container = _resolveComponent("customer-mobile-container")!
  const _component_loading_animation = _resolveComponent("loading-animation")!
  const _component_normlist_modal = _resolveComponent("normlist-modal")!
  const _component_add_customer_modal = _resolveComponent("add-customer-modal")!
  const _component_side_container = _resolveComponent("side-container")!
  const _component_main_container = _resolveComponent("main-container")!

  return (_openBlock(), _createBlock(_component_main_container, {
    title: _ctx.$t('customer_overview'),
    id: 'customer_administration'
  }, {
    actionButton: _withCtx(() => [
      _createVNode(_component_custom_button, {
        class: "w-auto",
        id: 'add_customer',
        name: 'add_customer',
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openAddCustomer()))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_icon_plus, {
              color: 'white',
              class: "mr-3"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t("customer")), 1)
          ])
        ]),
        _: 1
      })
    ]),
    append: _withCtx(() => [
      _createVNode(_component_side_container, {
        open: _ctx.isCustomerDetailsOpen,
        onEditNorm: _cache[8] || (_cache[8] = ($event: any) => (_ctx.openEditCustomer())),
        onOpenNormlist: _cache[9] || (_cache[9] = ($event: any) => (_ctx.openNormList(_ctx.customer))),
        onClose: _cache[10] || (_cache[10] = ($event: any) => (_ctx.closeSideContainer()))
      }, null, 8, ["open"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_input_search, {
          class: "w-64 h-10",
          id: 'customerFilter',
          name: 'customerFilter',
          modelValue: _ctx.params.search,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.params.search) = $event)),
          placeholder: _ctx.$t('name')
        }, null, 8, ["modelValue", "placeholder"]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setFilter('has_norm_manager'))),
            class: "flex cursor-pointer w-28 lg:w-48 ml-3"
          }, [
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("NM")), 1),
            _createVNode(_component_icon_arrow, {
              class: _normalizeClass([`${
                            _ctx.params.sort == 'has_norm_manager' ||
                            _ctx.params.sort == '-has_norm_manager'
                                ? 'flex'
                                : 'hidden'
                        } ${
                            _ctx.params.sort == 'has_norm_manager'
                                ? 'rotate-180'
                                : ''
                        }`, "transform scale-75 transition-all"]),
              color: 'grey-dark'
            }, null, 8, ["class"])
          ]),
          _createElementVNode("div", {
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setFilter('name'))),
            class: "flex cursor-pointer w-90 lg:w-96"
          }, [
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("name")), 1),
            _createVNode(_component_icon_arrow, {
              class: _normalizeClass([`${
                            _ctx.params.sort == 'name' || _ctx.params.sort == '-name'
                                ? 'flex'
                                : 'hidden'
                        } ${_ctx.params.sort == 'name' ? 'rotate-180' : ''}`, "transform scale-75 transition-all"]),
              color: 'grey-dark'
            }, null, 8, ["class"])
          ]),
          _createElementVNode("div", {
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setFilter('norms_count'))),
            class: "flex cursor-pointer w-36"
          }, [
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("norms_count")), 1),
            _createVNode(_component_icon_arrow, {
              class: _normalizeClass([`${
                            _ctx.params.sort == 'norms_count' ||
                            _ctx.params.sort == '-norms_count'
                                ? 'flex'
                                : 'hidden'
                        } ${_ctx.params.sort == 'norms_count' ? 'rotate-180' : ''}`, "transform scale-75 transition-all"]),
              color: 'grey-dark'
            }, null, 8, ["class"])
          ])
        ]),
        (!_ctx.isLoading && _ctx.customerList.length == 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t("no_search_result")), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customerList, (customer) => {
            return (_openBlock(), _createBlock(_component_customer_container, {
              class: "md:flex hidden",
              key: customer.id,
              customer: customer,
              onOpenNormlist: _ctx.openNormList,
              onEditCustomer: _ctx.openEditCustomer,
              onResendInvite: ($event: any) => (_ctx.prepareResendInvite(customer)),
              onClick: ($event: any) => (
                        _ctx.$router.push({
                            name: 'CustomerAdministrationDetails',
                            params: { id: customer.id },
                        })
                    )
            }, null, 8, ["customer", "onOpenNormlist", "onEditCustomer", "onResendInvite", "onClick"]))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customerList, (customer) => {
            return (_openBlock(), _createBlock(_component_customer_mobile_container, {
              class: "md:hidden flex",
              key: customer.id,
              customer: customer,
              onOpenNormlist: _ctx.openNormList,
              onEditCustomer: _ctx.openEditCustomer,
              onResendInvite: ($event: any) => (_ctx.prepareResendInvite(customer))
            }, null, 8, ["customer", "onOpenNormlist", "onEditCustomer", "onResendInvite"]))
          }), 128))
        ]),
        (_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createVNode(_component_loading_animation, {
                id: 'loadingAnimation',
                name: 'loadingAnimation',
                class: "mt-2",
                animationStyle: {
                        radius: '12',
                        animationColor: 'grey',
                    }
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.showNormlistModal)
        ? (_openBlock(), _createBlock(_component_normlist_modal, {
            key: 0,
            onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showNormlistModal = false))
          }))
        : _createCommentVNode("", true),
      (_ctx.showAddUserModal)
        ? (_openBlock(), _createBlock(_component_add_customer_modal, {
            key: 1,
            onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showAddUserModal = false)),
            onDelete: _cache[7] || (_cache[7] = ($event: any) => (
                (_ctx.showAddUserModal = false),
                    (_ctx.isCustomerDetailsOpen = false),
                    (_ctx.errorMessages = [])
            ))
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title"]))
}