import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  id: "sideContainerHeader",
  class: "flex justify-between w-full items-center sticky -top-0 h-20 left-0 right-0 bg-white"
}
const _hoisted_2 = { class: "flex items-center justify-end w-full" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = {
  key: 0,
  id: "sideContainerContent",
  class: "w-full mt-10"
}
const _hoisted_6 = { class: "flex h-auto mb-4" }
const _hoisted_7 = { class: "text-grey-darker text-sm w-40 flex-shrink-0" }
const _hoisted_8 = {
  key: 0,
  class: "text-grey-darker text-sm"
}
const _hoisted_9 = {
  key: 1,
  class: "text-grey-darker text-sm"
}
const _hoisted_10 = { class: "flex h-auto mb-4" }
const _hoisted_11 = { class: "text-grey-darker text-sm w-40 flex-shrink-0" }
const _hoisted_12 = {
  key: 0,
  class: "text-grey-darker text-sm"
}
const _hoisted_13 = {
  key: 1,
  class: "text-grey-darker text-sm"
}
const _hoisted_14 = { class: "flex h-auto mb-4" }
const _hoisted_15 = { class: "text-grey-darker text-sm w-40 flex-shrink-0" }
const _hoisted_16 = {
  key: 0,
  class: "text-grey-darker text-sm"
}
const _hoisted_17 = {
  key: 1,
  class: "text-grey-darker text-sm"
}
const _hoisted_18 = { class: "flex h-auto mb-4" }
const _hoisted_19 = { class: "text-grey-darker text-sm w-40 flex-shrink-0" }
const _hoisted_20 = {
  key: 0,
  class: "text-grey-darker text-sm"
}
const _hoisted_21 = {
  key: 1,
  class: "text-grey-darker text-sm"
}
const _hoisted_22 = {
  key: 1,
  class: "w-full mt-10 h-1/2 flex items-center justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_icon_close = _resolveComponent("icon-close")!
  const _component_icon_container = _resolveComponent("icon-container")!
  const _component_loading_animation = _resolveComponent("loading-animation")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["absolute xl:relative top-0 right-0 h-full bg-white p-3 pt-0 pl-5 transform transition-all duration-300", 
            _ctx.open
                ? 'w-full md:w-1/2 overflow-y-auto shadow-dark'
                : 'w-0 -mr-8 overflow-hidden'
        ])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[3] || (_cache[3] = _createElementVNode("img", {
        src: "/logo.png",
        class: "h-7 hidden xs:flex flex-shrink-0 mr-5"
      }, null, -1)),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_custom_button, {
          class: "w-auto mr-3",
          id: 'create_normlist',
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('openNormlist')))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("normlist")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_custom_button, {
          class: "w-auto",
          id: 'edit_norm',
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('editNorm')))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("edit")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_icon_container, {
          id: 'closeIcon',
          class: "ml-2",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_icon_close)
          ]),
          _: 1
        })
      ])
    ]),
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("name")), 1),
              (_ctx.customer && _ctx.customer.name)
                ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.customer.name), 1))
                : (_openBlock(), _createElementBlock("p", _hoisted_9, "-"))
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t("has_nm")), 1),
              (_ctx.customer && _ctx.customer.has_norm_manager)
                ? (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(_ctx.$t("yes")), 1))
                : (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(_ctx.$t("no")), 1))
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.$t("assigned_norms")), 1),
              (_ctx.customer && _ctx.customer.norms_count)
                ? (_openBlock(), _createElementBlock("p", _hoisted_16, _toDisplayString(_ctx.customer.norms_count), 1))
                : (_openBlock(), _createElementBlock("p", _hoisted_17, "-"))
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.$t("comment")), 1),
              (_ctx.customer && _ctx.customer.annotations)
                ? (_openBlock(), _createElementBlock("p", _hoisted_20, _toDisplayString(_ctx.customer.annotations), 1))
                : (_openBlock(), _createElementBlock("p", _hoisted_21, "-"))
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_22, [
          _createVNode(_component_loading_animation, {
            id: 'loadingAnimation',
            name: 'loadingAnimation',
            class: "mt-2",
            animationStyle: {
                    radius: '12',
                    animationColor: 'grey',
                }
          })
        ]))
  ], 2))
}