import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-center w-full" }
const _hoisted_2 = { class: "text-lg font-medium" }
const _hoisted_3 = { class: "text-sm text-grey-darkest mt-2" }
const _hoisted_4 = { class: "flex w-full mt-4 justify-between items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_default = _resolveComponent("input-default")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_mini_modal = _resolveComponent("mini-modal")!

  return (_openBlock(), _createBlock(_component_mini_modal, {
    onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('close'))),
    isCentered: true,
    mainContainerId: 'customer_administration'
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("duplication_customer")), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("duplication_text")), 1),
        _createVNode(_component_input_default, {
          id: 'name',
          name: 'name',
          modelValue: _ctx.copyName,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.copyName) = $event)),
          placeholder: _ctx.editCustomer.name + ' - Kopie 1',
          class: "w-full mt-4"
        }, null, 8, ["modelValue", "placeholder"]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_custom_button, {
            class: "w-auto ml-auto mr-6",
            id: 'copy-cancel-button',
            isPlainButton: true,
            buttonStyle: {
                        plainTextColor: 'grey-darker'
                    },
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("cancel")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_custom_button, {
            class: "w-auto mr-auto",
            id: 'copy-button',
            buttonStyle: {
                        standardBackgroundColor: 'primary',
                        standardBackgroundHoverColor: 'primary-dark',
                    },
            isDisabled: _ctx.copyName != '' && _ctx.copyName != null ? false : true,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('copy', _ctx.copyName)
                    ))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("duplicate")), 1)
            ]),
            _: 1
          }, 8, ["isDisabled"])
        ])
      ])
    ]),
    _: 1
  }))
}