import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "text-primary font-medium mb-6" }
const _hoisted_2 = { class: "flex justify-between mb-6" }
const _hoisted_3 = { class: "flex justify-between mt-2 mb-4" }
const _hoisted_4 = { class: "text-grey-darkest" }
const _hoisted_5 = { class: "text-sm text-grey" }
const _hoisted_6 = {
  key: 0,
  class: "flex justify-between mt-6"
}
const _hoisted_7 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_avatar_container = _resolveComponent("avatar-container")!
  const _component_input_default = _resolveComponent("input-default")!
  const _component_input_textarea = _resolveComponent("input-textarea")!
  const _component_input_switch = _resolveComponent("input-switch")!
  const _component_icon_delete = _resolveComponent("icon-delete")!
  const _component_centered_modal = _resolveComponent("centered-modal")!
  const _component_image_upload_modal = _resolveComponent("image-upload-modal")!
  const _component_confirm_delete_modal = _resolveComponent("confirm-delete-modal")!
  const _component_confirm_duplication_modal = _resolveComponent("confirm-duplication-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_centered_modal, {
      onClose: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('close'))),
      title: _ctx.editCustomer.id ? _ctx.$t('edit_customer') : _ctx.$t('add_customer'),
      cancelButtonText: _ctx.$t('cancel'),
      mainContainerId: 'customer_administration'
    }, {
      actionButton: _withCtx(() => [
        _createVNode(_component_custom_button, {
          id: 'save-button',
          class: "max-w-full",
          isDisabled: !_ctx.isAllFilled,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.editCustomer.id ? _ctx.updateCustomerData() : _ctx.addCustomer()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.editCustomer.id ? _ctx.$t("save") : _ctx.$t("apply")), 1)
          ]),
          _: 1
        }, 8, ["isDisabled"])
      ]),
      content: _withCtx(() => [
        _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t("customer_data")), 1),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_avatar_container, {
            id: 'profil-avatar',
            name: _ctx.editCustomer.name ?? 'Lauer',
            src: _ctx.editCustomer.avatar_url
          }, null, 8, ["name", "src"]),
          _createElementVNode("p", {
            class: "text pr-4 mt-3 text-primary font-medium cursor-pointer",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openImageUpload = true))
          }, _toDisplayString(_ctx.$t("settings.upload_image")), 1)
        ]),
        _createVNode(_component_input_default, {
          id: 'name',
          name: 'name',
          modelValue: _ctx.editCustomer.name,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editCustomer.name) = $event)),
          isRequired: true,
          label: _ctx.$t('name'),
          class: "w-full mb-1"
        }, null, 8, ["modelValue", "label"]),
        _createVNode(_component_input_default, {
          id: 'email',
          name: 'email',
          modelValue: _ctx.editCustomer.email,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editCustomer.email) = $event)),
          label: _ctx.$t('email'),
          class: "w-full mb-1"
        }, null, 8, ["modelValue", "label"]),
        _createVNode(_component_input_textarea, {
          id: 'customer-annotations',
          name: "customer-annotations",
          label: _ctx.$t('comment'),
          rows: 3,
          modelValue: _ctx.editCustomer.annotations,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editCustomer.annotations) = $event))
        }, null, 8, ["label", "modelValue"]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", null, [
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("licensing")), 1),
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("licensing_text")), 1)
          ]),
          _createVNode(_component_input_switch, {
            id: 'switch-give-has-norm-manager',
            name: 'switch-give-has-norm-manager',
            modelValue: _ctx.editCustomer.has_norm_manager,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editCustomer.has_norm_manager) = $event)),
            tabIndex: 2,
            class: "mt-2"
          }, null, 8, ["modelValue"])
        ]),
        (_ctx.editCustomer.id)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_custom_button, {
                id: 'delete-button',
                class: "max-w-full",
                isPlainButton: true,
                buttonStyle: {
                        plainTextColor: 'red',
                        plainBackgroundHoverColor: 'grey-lightest',
                    },
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.openConfirmDelete = true))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_icon_delete, {
                      color: 'red',
                      class: "mr-3"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t("delete_customer")), 1)
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_custom_button, {
                id: 'copy-button',
                class: "max-w-full",
                buttonStyle: {
                        plainTextColor: 'primary',
                        plainBackgroundHoverColor: 'grey-lightest',
                    },
                onClick: _cache[7] || (_cache[7] = ($event: any) => ((_ctx.openDuplication = true), (_ctx.errorMessages = [])))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("create_duplicat")), 1)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["title", "cancelButtonText"]),
    (_ctx.openImageUpload)
      ? (_openBlock(), _createBlock(_component_image_upload_modal, {
          key: 0,
          onClose: _cache[9] || (_cache[9] = ($event: any) => (_ctx.openImageUpload = false)),
          mainContainerId: 'customer_administration',
          onSaveImage: _ctx.saveImage
        }, null, 8, ["onSaveImage"]))
      : _createCommentVNode("", true),
    _createVNode(_component_confirm_delete_modal, {
      open: _ctx.openConfirmDelete,
      type: 'Kunden',
      onClose: _cache[10] || (_cache[10] = ($event: any) => (_ctx.openConfirmDelete = false)),
      onDelete: _cache[11] || (_cache[11] = ($event: any) => (_ctx.removeCustomer()))
    }, null, 8, ["open"]),
    (_ctx.openDuplication)
      ? (_openBlock(), _createBlock(_component_confirm_duplication_modal, {
          key: 1,
          type: 'Kunden',
          customerName: _ctx.editCustomer.name,
          onClose: _cache[12] || (_cache[12] = ($event: any) => (_ctx.openDuplication = false)),
          onCopy: _ctx.copyCustomer
        }, null, 8, ["customerName", "onCopy"]))
      : _createCommentVNode("", true)
  ], 64))
}