import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-28 lg:w-48 ml-2 mr-1" }
const _hoisted_2 = { class: "w-90 lg:w-96" }
const _hoisted_3 = { class: "text-sm text-grey-darkest truncate sm:w-auto w-40" }
const _hoisted_4 = { class: "w-72" }
const _hoisted_5 = { class: "text-sm text-grey-darkest" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_check = _resolveComponent("icon-check")!
  const _component_icon_error = _resolveComponent("icon-error")!
  const _component_loading_animation = _resolveComponent("loading-animation")!
  const _component_icon_mail = _resolveComponent("icon-mail")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["h-9 flex-shrink-0 bg-white-dark hover:bg-grey-lightest rounded-md flex gap-3 items-center relative cursor-pointer", `border-l-2 ${
            _ctx.$route.params.id == _ctx.customer.id
                ? 'border-primary bg-grey-lightest hover:bg-grey-lighter'
                : 'border-transparent'
        } transform transition-all`])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.customer.has_norm_manager)
        ? (_openBlock(), _createBlock(_component_icon_check, {
            key: 0,
            color: 'green'
          }))
        : (_openBlock(), _createBlock(_component_icon_error, { key: 1 }))
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.customer.name), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.customer.norms_count), 1)
    ]),
    (_ctx.customer.is_verified === false)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "flex absolute right-3 items-center justify-center h-7 w-7 flex-shrink-0 rounded-md hover:bg-grey-light cursor-pointer",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('resendInvite')), ["stop"]))
        }, [
          (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_loading_animation, {
                key: 0,
                id: 'loadingAnimation',
                name: 'loadingAnimation',
                animationStyle: {
                    radius: '12',
                    animationColor: 'primary',
                }
              }))
            : (_openBlock(), _createBlock(_component_icon_mail, {
                key: 1,
                color: 'primary'
              }))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}