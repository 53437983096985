
import { computed, defineComponent, ref } from "vue";
import IconCheck from "@/components/icons/IconCheckbox.vue";
import IconError from "@/components/icons/IconClose2.vue";
import IconArrow from "@/components/icons/IconArrowBottom.vue";
import IconMail from "@/components/icons/IconMail.vue";
import LoadingAnimation from "@/components/helpers/loadingAnimation/LoadingAnimation.vue";
import { CustomerListItemInterface } from "@/store/customer/interfaces";
import { storeToRefs } from "pinia";
import { useCustomerStore } from "@/store/customer";
import { useHelpersStore } from "@/store/helpers";

export default defineComponent({
    name: "CustomerContainer",
    emits: ["resendInvite"],
    props: {
        customer: {
            type: Object as () => CustomerListItemInterface,
            required: true,
        },
    },
    components: {
        IconError,
        IconCheck,
        IconArrow,
        IconMail,
        LoadingAnimation,
    },
    setup(props) {
        const isOpen = ref(false);
        const { editCustomer } = storeToRefs(useCustomerStore());
        const { loadingIds } = storeToRefs(useHelpersStore());
        const isLoading = computed(() => {
            return loadingIds.value.some(
                (id) => id.id == "resend-mail-" + props.customer.id
            );
        });

        return { isOpen, editCustomer, isLoading };
    },
});
