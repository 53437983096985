import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex gap-2 items-center" }
const _hoisted_2 = { class: "text-sm text-grey-darkest truncate sm:w-auto w-40" }
const _hoisted_3 = { class: "relative" }
const _hoisted_4 = { class: "flex gap-2 flex-shrink-0" }
const _hoisted_5 = { class: "text-black-light text-sm" }
const _hoisted_6 = { class: "text-primary-darkest text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_check = _resolveComponent("icon-check")!
  const _component_icon_error = _resolveComponent("icon-error")!
  const _component_icon_arrow = _resolveComponent("icon-arrow")!
  const _component_loading_animation = _resolveComponent("loading-animation")!
  const _component_icon_mail = _resolveComponent("icon-mail")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["md:hidden p-2 gap-2 flex flex-col bg-white-dark hover:bg-grey-lightest flex-shrink-0 rounded-md cursor-pointer overflow-hidden", `${
            _ctx.isOpen ? 'max-h-216' : 'max-h-10'
        } border-l-2 transform transition-all ${
            _ctx.customer.id == _ctx.editCustomer.id
                ? 'border-primary bg-grey-lightest hover:bg-grey-lighter'
                : 'border-transparent'
        }`]),
    onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.isOpen = !_ctx.isOpen), ["stop"]))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex border-b w-full pb-2 justify-between items-center", `${_ctx.isOpen ? 'border-grey-lighter' : 'border-transparent'}`])
    }, [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.customer.has_norm_manager)
          ? (_openBlock(), _createBlock(_component_icon_check, {
              key: 0,
              color: 'green'
            }))
          : (_openBlock(), _createBlock(_component_icon_error, { key: 1 })),
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.customer.name), 1)
      ]),
      _createVNode(_component_icon_arrow, {
        class: _normalizeClass(`${
                    _ctx.isOpen ? 'rotate-180' : ''
                } transform transition-all flex-shrink-0`)
      }, null, 8, ["class"]),
      (_ctx.customer.is_verified === false)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "flex absolute right-10 items-center justify-center h-7 w-7 flex-shrink-0 rounded-md hover:bg-grey-light cursor-pointer",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('resendInvite')), ["stop"]))
          }, [
            (_ctx.isLoading)
              ? (_openBlock(), _createBlock(_component_loading_animation, {
                  key: 0,
                  id: 'loadingAnimation',
                  name: 'loadingAnimation',
                  animationStyle: {
                        radius: '12',
                        animationColor: 'primary',
                    }
                }))
              : (_openBlock(), _createBlock(_component_icon_mail, {
                  key: 1,
                  color: 'primary'
                }))
          ]))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _cache[3] || (_cache[3] = _createElementVNode("p", { class: "text-grey-dark text-sm" }, "Normenanzahl", -1)),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.customer.norms_count), 1)
      ])
    ]),
    _createElementVNode("div", {
      class: "w-full border-t border-grey-lighter flex items-center justify-center pt-2 mt-2",
      onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (
                _ctx.$router.push({
                    name: 'CustomerAdministrationDetails',
                    params: { id: _ctx.customer.id },
                })
            ), ["stop"]))
    }, [
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("open_details")), 1)
    ])
  ], 2))
}