
import { defineComponent, onMounted, ref } from "vue";
import MiniModal from "@/components/layout/modals/MiniModal.vue";
import CustomButton from "@/components/inputs/customButton/CustomButton.vue";
import InputDefault from "@/components/inputs/inputDefault/InputDefault.vue";
import { useCustomerStore } from "@/store/customer";
import { storeToRefs } from "pinia";

export default defineComponent({
    name: "confirmDuplicateModal",
    components: { MiniModal, CustomButton, InputDefault },
    props: {
        customerName: {
            type: String,
            required: false,
        },
    },
    emits: ["close", "copy"],
    setup() {
        const copyName = ref("");
        const { editCustomer } = storeToRefs(useCustomerStore());

        onMounted(() => {
            copyName.value = editCustomer.value.name + ' - Kopie 1'

        })
        return {
            copyName,
            editCustomer,
        };
    },
});
