
import MainContainer from "@/components/layout/mainContainer/MainContainer.vue";
import CustomButton from "@/components/inputs/customButton/CustomButton.vue";
import InputSearch from "@/components/inputs/inputSearch/InputSearch.vue";
import IconPlus from "@/components/icons/IconPlus.vue";
import CustomerContainer from "./containers/CustomerContainer.vue";
import CustomerMobileContainer from "./containers/CustomerMobileContainer.vue";
import NormlistModal from "./modals/AddNormlistModal.vue";
import AddCustomerModal from "./modals/AddCustomerModal.vue";
import LoadingAnimation from "@/components/helpers/loadingAnimation/LoadingAnimation.vue";
import SideContainer from "./containers/SideContainer.vue";
import IconArrow from "@/components/icons/IconArrowBigBottom.vue";
import {
    computed,
    defineComponent,
    onMounted,
    onUnmounted,
    ref,
    watch,
} from "vue";
import { useCustomerStore } from "@/store/customer";
import { storeToRefs } from "pinia";
import {
    CustomerDataInterface,
    CustomerListItemInterface,
} from "@/store/customer/interfaces";
import { useHelpersStore } from "@/store/helpers";
import { useRoute, useRouter } from "vue-router";
import { cloneData } from "@/common/cloneData";
import { useI18n } from "vue-i18n";
import { ToastMessageType } from "@/components/helpers/theToastMessage/toast_message_helpers";

export default defineComponent({
    name: "CustomerAdministration",
    components: {
        MainContainer,
        CustomButton,
        IconPlus,
        InputSearch,
        CustomerContainer,
        NormlistModal,
        AddCustomerModal,
        LoadingAnimation,
        SideContainer,
        IconArrow,
        CustomerMobileContainer,
    },
    setup() {
        const { t } = useI18n();
        const {
            customerList,
            customer,
            editCustomer,
            customerPaginator,
            params,
        } = storeToRefs(useCustomerStore());
        const { fetchCustomers, fetchCustomerData, resendInvite } =
            useCustomerStore();
        const { setLoadingId, removeLoadingId, setToastMessage } =
            useHelpersStore();
        const { loadingIds, errorMessages } = storeToRefs(useHelpersStore());
        const showNormlistModal = ref(false);
        const showAddUserModal = ref(false);
        const isCustomerDetailsOpen = ref(false);
        const isEdit = ref(false);
        const router = useRouter();
        const route = useRoute();

        var typingTimer: ReturnType<typeof setTimeout>;
        watch(
            () => [params.value.search],
            () => {
                clearTimeout(typingTimer);
                typingTimer = setTimeout(async () => {
                    getCustomers(true);
                }, 500);
            }
        );

        watch(
            () => route.path,
            () => {
                const id = route.params.id?.toString();
                const routeName = route.name?.toString();
                if (
                    routeName == "CustomerAdministration" ||
                    routeName == "CustomerAdministrationDetails"
                ) {
                    if (!id) {
                        isCustomerDetailsOpen.value = false;
                    } else {
                        openCustomerDetails(id);
                    }
                }
            }
        );

        const isLoading = computed(() => {
            return loadingIds.value.find(
                (element) => element.name == "fetch_customers_loading"
            );
        });

        function openAddCustomer(): void {
            showAddUserModal.value = true;
            editCustomer.value = { has_norm_manager: false };
            router.push({ name: "CustomerAdministration" });
        }

        function closeSideContainer(): void {
            isCustomerDetailsOpen.value = false;
            editCustomer.value = { has_norm_manager: false };
            router.push({ name: "CustomerAdministration" });
        }

        function openNormList(customer: CustomerDataInterface): void {
            editCustomer.value = customer;
            showNormlistModal.value = true;
        }

        async function openEditCustomer(): Promise<void> {
            editCustomer.value = cloneData(customer.value);
            showAddUserModal.value = true;
            isEdit.value = true;
        }

        async function getCustomers(reset: boolean): Promise<void> {
            if (reset) {
                customerPaginator.value.page = 1;
                customerPaginator.value.endOfList = false;
            }
            if (!customerPaginator.value.endOfList) {
                setLoadingId("fetch_customers_loading");
                await fetchCustomers();
                removeLoadingId("fetch_customers_loading");
            }
        }

        function handleScroll() {
            if (!customerPaginator.value.endOfList) {
                let element = document.getElementById("customer_list");
                if (
                    element &&
                    element?.scrollTop + element?.clientHeight + 1 >=
                        element?.scrollHeight
                ) {
                    customerPaginator.value.page++;
                    getCustomers(false);
                }
            }
        }
        function setFilter(filter: string) {
            if (params.value?.sort == filter) {
                if (params.value?.sort[0] != "-") {
                    params.value.sort = "-" + params.value?.sort;
                } else {
                    customerPaginator.value.page = 1;
                    customerPaginator.value.endOfList = false;
                }
            } else {
                params.value.sort = filter;
            }

            getCustomers(true);
        }

        async function openCustomerDetails(
            customerId: number | string
        ): Promise<void> {
            if (customerId) {
                setLoadingId("fetchingCustomerData");
                isCustomerDetailsOpen.value = true;
                const response = await fetchCustomerData(customerId);
                if (response?.status != 200) {
                    isCustomerDetailsOpen.value = false;
                    router.push({ name: "CustomerAdministration" });
                }
                removeLoadingId("fetchingCustomerData");
            }
        }

        onMounted(() => {
            customerList.value = [];
            getCustomers(true);
            const list = document.getElementById("customer_list");
            if (list) {
                list?.addEventListener("scroll", handleScroll);
            }
            if (route.params.id) {
                let routeId = route.params.id as string;
                openCustomerDetails(routeId);
            } else {
                isCustomerDetailsOpen.value = false;
                router.push({ name: "CustomerAdministration" });
            }
        });

        async function prepareResendInvite(
            customer: CustomerListItemInterface
        ) {
            setLoadingId("resend-mail-" + customer.id);
            const succeeded = await resendInvite(customer);
            if (succeeded) {
                setToastMessage(
                    ToastMessageType.SUCCESS,
                    t("toast_message.resend_email")
                );
            } else {
                setToastMessage(
                    ToastMessageType.ERROR,
                    t("toast_message.resend_email_failed")
                );
            }
            removeLoadingId("resend-mail-" + customer.id);
        }

        onUnmounted(() => {
            const list = document.getElementById("customer_list");
            if (list) {
                list?.removeEventListener("scroll", handleScroll);
            }
        });
        return {
            customerList,
            showNormlistModal,
            openNormList,
            showAddUserModal,
            openEditCustomer,
            customer,
            isLoading,
            isCustomerDetailsOpen,
            openAddCustomer,
            closeSideContainer,
            params,
            errorMessages,
            setFilter,
            prepareResendInvite,
        };
    },
});
