
import { computed, defineComponent, ref } from "vue";
import CenteredModal from "@/components/layout/modals/CenteredModal.vue";
import CustomButton from "@/components/inputs/customButton/CustomButton.vue";
import InputDefault from "@/components/inputs/inputDefault/InputDefault.vue";
import InputSwitch from "@/components/inputs/inputSwitch/InputSwitch.vue";
import InputTextarea from "@/components/inputs/inputTextarea/InputTextarea.vue";
import IconDelete from "@/components/icons/IconTrash.vue";
import ConfirmDeleteModal from "../../settings/modals/ConfirmDeleteModal.vue";
import ConfirmDuplicationModal from "./ConfirmDuplicationModal.vue";
import ImageUploadModal from "../../settings/views/profil/ImageUploadModal.vue";
import AvatarContainer from "@/components/helpers/avatarContainer/AvatarContainer.vue";
import { storeToRefs } from "pinia";
import { useCustomerStore } from "@/store/customer";
import { useHelpersStore } from "@/store/helpers";
import { cloneData } from "@/common/cloneData";
import { CustomerDataInterface } from "@/store/customer/interfaces";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { ToastMessageType } from "@/components/helpers/theToastMessage/toast_message_helpers";

export default defineComponent({
    name: "addCustomerModal",
    components: {
        CenteredModal,
        CustomButton,
        InputDefault,
        InputTextarea,
        InputSwitch,
        AvatarContainer,
        ImageUploadModal,
        IconDelete,
        ConfirmDeleteModal,
        ConfirmDuplicationModal,
    },
    emits: ["close", "delete"],
    setup(props, ctx) {
        const { t } = useI18n();
        const { editCustomer } = storeToRefs(useCustomerStore());
        const {
            createCustomer,
            updateCustomer,
            cloneCustomer,
            deleteCustomer,
        } = useCustomerStore();
        const { setLoadingId, removeLoadingId, setToastMessage } =
            useHelpersStore();

        const { errorMessages } = storeToRefs(useHelpersStore());
        const openImageUpload = ref(false);

        const openConfirmDelete = ref(false);
        const openDuplication = ref(false);

        const router = useRouter();

        async function addCustomer(): Promise<void> {
            setLoadingId("save-button");
            const succeeded = await createCustomer();
            if (succeeded) {
                setToastMessage(
                    ToastMessageType.SUCCESS,
                    t("create_customer_succeeded")
                );
                ctx.emit("close");
            } else {
                setToastMessage(
                    ToastMessageType.ERROR,
                    t("create_customer_failed")
                );
            }
            removeLoadingId("save-button");
        }
        async function updateCustomerData(): Promise<void> {
            setLoadingId("save-button");
            const succeeded = await updateCustomer();
            if (succeeded) {
                setToastMessage(
                    ToastMessageType.SUCCESS,
                    t("update_customer_succeeded")
                );
                ctx.emit("close");
            } else {
                setToastMessage(
                    ToastMessageType.ERROR,
                    t("update_customer_failed")
                );
            }
            removeLoadingId("save-button");
        }

        async function removeCustomer(): Promise<void> {
            setLoadingId("confirm-delete-button");
            const succeeded = await deleteCustomer();
            if (succeeded) {
                setToastMessage(
                    ToastMessageType.SUCCESS,
                    t("delete_customer_succeeded")
                );
                router.push({ name: "CustomerAdministration" });
                ctx.emit("delete");
            } else {
                setToastMessage(
                    ToastMessageType.ERROR,
                    t("delete_customer_failed")
                );
            }
            removeLoadingId("confirm-delete-button");
        }

        async function copyCustomer(name: string): Promise<void> {
            let customerData: CustomerDataInterface = cloneData(
                editCustomer.value
            );
            customerData.name = name;
            setLoadingId("copy-button");
            const succeeded = await cloneCustomer(customerData);
            if (succeeded) {
                setToastMessage(
                    ToastMessageType.SUCCESS,
                    t("clone_customer_succeeded")
                );
                ctx.emit("close");
            } else {
                setToastMessage(
                    ToastMessageType.ERROR,
                    t("clone_customer_failed")
                );
            }
            removeLoadingId("copy-button");
        }
        async function saveImage(image: string): Promise<void> {
            editCustomer.value.avatar = image;
            editCustomer.value.avatar_url = image;
            openImageUpload.value = false;
        }

        const isAllFilled = computed(() => {
            let isFilled = false;
            if (editCustomer.value.name) {
                isFilled = true;
            }
            return isFilled;
        });
        return {
            isAllFilled,
            editCustomer,
            openImageUpload,
            openConfirmDelete,
            openDuplication,
            addCustomer,
            updateCustomerData,
            copyCustomer,
            saveImage,
            removeCustomer,
            errorMessages,
        };
    },
});
