
import { computed, defineComponent } from "vue";
import IconCheck from "@/components/icons/IconCheckbox.vue";
import IconError from "@/components/icons/IconClose2.vue";
import IconMail from "@/components/icons/IconMail.vue";
import LoadingAnimation from "@/components/helpers/loadingAnimation/LoadingAnimation.vue";
import { CustomerListItemInterface } from "@/store/customer/interfaces";
import { useHelpersStore } from "@/store/helpers";
import { storeToRefs } from "pinia";

export default defineComponent({
    name: "CustomerContainer",
    emits: ["resendInvite"],
    props: {
        customer: {
            type: Object as () => CustomerListItemInterface,
            required: true,
        },
    },
    components: {
        IconError,
        IconCheck,
        IconMail,
        LoadingAnimation,
    },
    setup(props) {
        const { loadingIds } = storeToRefs(useHelpersStore());
        const isLoading = computed(() => {
            return loadingIds.value.some(
                (id) => id.id == "resend-mail-" + props.customer.id
            );
        });
        return { isLoading };
    },
});
